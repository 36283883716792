'use client'

import {
  Box,
  Flex,
  HStack,
} from '@chakra-ui/react'

export default function Simple() {
  return (
    <>
      <Box backgroundColor="#177fcb" px={4} py={2}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <HStack spacing={8} alignItems={'left'}>
            <div class="nav-cont">
              <img class="nav-logo" src='/bt11.jpg'></img>
              <h1 class="logo font-bold mainTxt pl-4">
                BIG BOOTY <span class="acc">LOVE</span>
              </h1>
            </div>
          </HStack>
          <div class="socials">
            <a href='https://twitter.com/BootyCoinSOL'>
              <img class="nav-logo" src='/x.png'></img>
            </a>
            <a href='https://t.me/bootycoinsol'>
              <img class="nav-logo" src='/tg.png'></img>
            </a>
          </div>
        </Flex>
      </Box>
    </>
  )
}