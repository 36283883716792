import './App.css';
import Nav from './Nav';
import { Container } from '@chakra-ui/react'
import {CanvasComponent} from './Canvas'

function App() {
  return (
    <>
    <Nav />

      <div class="main">
          <Container class="mx-auto tp-section">
              <div class="text-center mumu py-10">
                  <h2 class="font-bold linear-wipe">
                    Discover <span class="acc"> BOOTY ($BOOTY) </span>
                    <div class="acc-txt">BIG BOOTY <span class="acc">LOVE (🍑)</span> </div> 
                  </h2>
                  <div class="sub">
                    <div>Double cheek squeezes in my upper glutes. Bussin every Booty post in the TL. </div>
                    <div> My own suga daddy makin money moves. Twerking in 7 different chats. </div>
                    <div>Chronic booty admiration. 4 hours of squats. $20k to look like Kim K. </div>
                    <div> The only BOOTY i'm gonna get.</div> 
                  </div>
                  <button class="logo rounded-full px-6 py-2 my-4 font-bold border-2 hover:bg-yellow-400">
                    <a href="https://dexscreener.com/solana/cf3w6vscfwbngocuxxqxgjhoourbjkampgybmsw2es1v">
                      BUY NOW 
                    </a>
                  </button>
              </div>
          </Container>
          <Container class="mx-auto ca">
            CA AGLLcdsYYNQiwmLLnc67DZzXQk1BGyNxsADgjdwPBMA1
          </Container>
          <CanvasComponent />
          <Container class="mx-auto">
            <div class="info">
              <div>Dive into the depths of the blockchain and discover the richest treasure yet - <span class="acc">BOOTY Coin ($BOOTY)</span> ! 
                <div>Crafted for the bold, the brave, and the believers in the power of fun and finance, <span class="acc">BOOTY</span> is not just a coin;</div>
                <div> it's a voyage into uncharted waters of opportunity and adventure.</div>
              </div>
            </div>
          </Container>
          <Container class="token py-12">
              <div id="token" class="container mx-auto px-6">
                  <h3 class="banTxt acc font-bold text-center mb-12">Chart Your Course to Riches! 🗺</h3>
                  <div class="flex flex-wrap justify-between items-center">
                     <div class="w-full lg:w-5/12 px-4 py-2">
                          <p class="text-white text-xl mb-4">
Whether you're here to trade the seven crypto-seas or to find a new digital domicile, BOOTY ($BOOTY) offers you an engaging, entertaining, and potentially profitable journey. With its unique blend of meme magic and robust technology, BOOTY is set to conquer the vast ocean of cryptocurrency.
</p>
                          <div class="bg-gray-700 overflow-hidden">
                            <img src="/mem2.jpg" />
                          </div>
                      </div>
                      <div class="w-full lg:w-5/12 px-4 py-2">
                          <p class="text-white text-xl mb-4">With BOOTY, every trade is an adventure, and every holder is part of the legend. Are you ready to embark on a quest for digital treasure? The map is drawn, and X marks the spot!
Disclaimer: The crypto seas are mysterious and unpredictable. Navigate wisely, invest with caution, and may your voyage be prosperous.
</p>
                          <div class="bg-gray-700  overflow-hidden">
                            <img src="/mem3.jpg" />
                          </div>
                      </div>
                  </div>
              </div>
          </Container>

          <footer class="footer py-6 mt-12">
              <div class="container mx-auto text-center">
              <div class="foot-cont"><p class="text-white">Join the BOOTY ($BOOTY) Brigade!</p><img class="nav-logo" src='/bt11.jpg'></img></div>
              </div>
          </footer>
      </div>
    </>
  );
}

export default App;
